import React from "react";

interface ExperienceCardProps {
  start: string;
  end: string;
  title: string;
  description: string;
  company: {
    name: string;
    url: string;
  };
}

const ExperienceCard: React.FC<ExperienceCardProps> = ({
  start,
  end,
  title,
  description,
  company,
}) => {
  return (
    <div className="">
      <p className="mt-4 text-gray-600">
        {start} - {end}
      </p>
      <div className="">
        <p className="text-xl">
          <b>
            {title} @{" "}
            <a
              className="inline-block border-b border-gray-700 hover:text-teal-900"
              href={company.url}
              target="_blank"
              rel="noreferrer"
            >
              {company.name}
            </a>
          </b>
        </p>
        <p>{description}</p>
      </div>
      <hr className="mt-4 border-t-2 border-gray-700" />
    </div>
  );
};

export default ExperienceCard;
