// src/components/Contacts.tsx
import React from "react";
import ContactCard from "./ContactCard";

const Contacts: React.FC = () => {
  return (
    <section className="mt-16">
      <div className="container mx-auto">
        <h1 className="md:text-6xl text-4xl font-bold mb-8">Contacts</h1>
        <ContactCard
          platform="ckrudwig@gmail.com"
          url="mailto:ckrudwig@gmail.com"
        />
        <ContactCard
          platform="LinkedIn"
          url="https://www.linkedin.com/in/cole-j-krudwig/"
        />
        <ContactCard platform="GitHub" url="https://github.com/Cole-Krudwig" />
        <ContactCard
          platform="Kaggle"
          url="https://www.kaggle.com/colekrudwig"
        />
        <ContactCard platform="Twitter" url="https://twitter.com/colekrudwig" />

        {/* Add more contact links as needed */}
        <hr className="border-t-2 border-gray-700" />
      </div>
    </section>
  );
};

export default Contacts;
