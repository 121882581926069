// src/components/ContactCard.tsx
import React from "react";
import { FaGithub, FaLinkedin, FaKaggle, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";

type Platform =
  | "GitHub"
  | "Twitter"
  | "LinkedIn"
  | "Kaggle"
  | "ckrudwig@gmail.com";

interface ContactCardProps {
  platform: Platform;
  url: string;
}

const iconMap: Record<Platform, JSX.Element> = {
  GitHub: <FaGithub size={32} />,
  Twitter: <FaXTwitter size={32} />,
  LinkedIn: <FaLinkedin size={32} />,
  Kaggle: <FaKaggle size={32} />,
  "ckrudwig@gmail.com": <FaEnvelope size={32} />,
};

const ContactCard: React.FC<ContactCardProps> = ({ platform, url }) => {
  return (
    <div>
      <a
        href={url}
        target="_blank"
        rel="noopener noreferrer"
        className="block border-t-2 border-gray-700 p-4 hover:bg-gray-100 transition-all duration-3000 ease-in-out group"
      >
        <div className="flex items-center">
          <div className="mr-4">
            {iconMap[platform]}{" "}
            {/* No need for fallback since platform is typed */}
          </div>
          <p className="text-xl transform transition-transform duration-3000 ease-in-out group-hover:ml-4">
            {platform}
          </p>
        </div>
      </a>
    </div>
  );
};

export default ContactCard;
