import React from "react";
import ExperienceCard from "./ExperienceCard";

const Experience: React.FC = () => {
  const experiences = [
    {
      start: "November 2023",
      end: "Present",
      title: "Data Science Intern",
      description:
        "Develop reinforcement learning algorithms to solve business operations challenges.",
      company: {
        name: "Valmont Industries",
        url: "https://www.valmont.com/",
      },
    },
    {
      start: "September 2023",
      end: "Present",
      title: "Research Scientist",
      description:
        "Conduct research in AI, computer vision, deep learning, and economic simulation.",
      company: {
        name: "Creighton University",
        url: "https://www.creighton.edu/",
      },
    },
    {
      start: "May 2022",
      end: "August 2022",
      title: "Investment Analyst Intern",
      description:
        "Collaborated with software developers and leasing agents to craft a premiere real-estate software solution for tenants and investors.",
      company: {
        name: "NorthPoint Development",
        url: "https://www.beyondthecontract.com/",
      },
    },
  ];

  return (
    <section className="mt-16">
      <div className="container mx-auto">
        <h1 className="md:text-6xl text-4xl font-bold mb-8">Experience</h1>
        {experiences.map((exp, index) => (
          <ExperienceCard
            key={index}
            start={exp.start}
            end={exp.end}
            title={exp.title}
            description={exp.description}
            company={exp.company}
          />
        ))}
      </div>
    </section>
  );
};

export default Experience;
