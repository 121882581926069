// src/components/Publications.tsx
import React from "react";
import PublicationCard from "./PublicationCard";

const Publications: React.FC = () => {
  return (
    <section className="mt-16">
      <div className="container mx-auto">
        <h1 className="md:text-6xl text-4xl font-bold mb-8">Publications</h1>
        <p>Publications in review...</p>
      </div>
    </section>
  );
};

export default Publications;
