import React from "react";
import Header from "./components/Header";
import ContactHeader from "./components/ContactHeader";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Teaching from "./components/Teaching";
import Publications from "./components/Publications";
import Contacts from "./components/Contacts";
import { motion } from "framer-motion";

import "./App.css";

const App: React.FC = () => {
  return (
    <div className="App">
      <div className="mx-12 font-mont">
        <Header />

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 2.0 }}
        >
          <ContactHeader />
        </motion.div>

        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.0, delay: 0.4 }}
        >
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, delay: 0.6 }} // Adjust delay as needed
          >
            <Experience />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, delay: 0.9 }} // Adjust delay as needed
          >
            <Projects />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, delay: 1.2 }} // Adjust delay as needed
          >
            <Teaching />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, delay: 1.5 }} // Adjust delay as needed
          >
            <Publications />
          </motion.div>

          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, delay: 1.8 }} // Adjust delay as needed
          >
            <Contacts />
          </motion.div>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, delay: 2.0 }} // Adjust delay as needed
          >
            <div className="py-16 container mx-auto">
              Cole Krudwig &copy; {new Date().getFullYear()}
            </div>
          </motion.div>
        </motion.div>
      </div>
    </div>
  );
};

export default App;
