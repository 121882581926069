import React from "react";
import { motion } from "framer-motion";

const typingVariants = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.2,
    },
  },
};

const typingVariants1 = {
  hidden: { opacity: 1 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.5,
      staggerChildren: 0.05,
    },
  },
};

const letterVariants = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
};

const Header: React.FC = () => {
  const name = "Cole Krudwig";
  const phrase1 = "Data Scientist & AI Researcher";
  const phrase2 =
    "I like to develop solutions using neural networks and machine learning.";

  return (
    <header className="mb-8 mt-12">
      <div className="container mx-auto">
        <div className="md:flex">
          <h1 className="lg:text-9xl text-6xl sm:text-8xl font-bold">
            <motion.div
              initial="hidden"
              animate="visible"
              variants={typingVariants}
              className="inline-block"
            >
              {name.split("").map((letter, index) => (
                <motion.span key={index} variants={letterVariants}>
                  {letter}
                </motion.span>
              ))}
            </motion.div>
          </h1>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 1.0, delay: 2.0 }}
          >
            <img
              src="/cole-krudwig-circ.png"
              alt=""
              className="hidden lg:ml-16 mt-8 md:mt-0 md:mr-16"
              width={200}
            />
          </motion.div>
        </div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={typingVariants1}
          className="mt-8 mb-4 text-2xl"
          style={{ whiteSpace: "pre-line" }}
        >
          {phrase1.split("").map((letter, index) => (
            <motion.span key={index} variants={letterVariants}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={typingVariants1}
          className="text-2xl"
          style={{ whiteSpace: "pre-line" }}
        >
          {phrase2.split("").map((letter, index) => (
            <motion.span key={index} variants={letterVariants}>
              {letter}
            </motion.span>
          ))}
        </motion.div>
      </div>
    </header>
  );
};

export default Header;
