// src/components/ProjectCard.tsx
import React from "react";

interface ProjectCardProps {
  image: string;
  title: string;
  link: string;
  description: string;
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  image,
  title,
  link,
  description,
}) => {
  return (
    <a
      href={link}
      target="_blank"
      rel="noopener noreferrer"
      className="block border-t-2 border-gray-700 p-4 flex items-center hover:bg-teal-900 transition-all duration-300 ease-in-out group"
    >
      <div className="flex-shrink-0 mr-4">
        <img src={image} alt={title} className="w-16 h-16 rounded-lg" />
      </div>
      <div className="relative">
        <div>
          <span className="text-xl font-bold transform transition-transform duration-300 ease-in-out group-hover:ml-4 group-hover:text-white group-hover:text-2xl">
            {title}
          </span>
          <span className="text-lg group-hover:text-white hidden md:inline">
            &nbsp;- {description}
          </span>
        </div>
      </div>
    </a>
  );
};

export default ProjectCard;
