// src/components/PublicationCard.tsx
import React from "react";

interface PublicationCardProps {
  event: string;
  date: string;
  title: string;
  link: string;
  description: string;
}

const TeachingCard: React.FC<PublicationCardProps> = ({
  event,
  date,
  title,
  link,
  description,
}) => {
  return (
    <div>
      <p className="mt-4 text-gray-600">
        {event} - {date}
      </p>
      <p className="text-xl font-bold  ">
        <a
          href={link}
          target="_blank"
          rel="noopener noreferrer"
          className="inline-block border-b border-gray-500 hover:text-teal-900"
        >
          {title}
        </a>
      </p>
      <p className="text-xl mt-1 hidden md:inline-block">{description}</p>
      <hr className="mt-4 border-t-2 border-gray-700 " />
    </div>
  );
};

export default TeachingCard;
