// src/components/Publications.tsx
import React from "react";
import TeachingCard from "./TeachingCard";

const Teaching: React.FC = () => {
  const teachings = [
    {
      event: "ML Tutorials",
      date: "June 2024",
      title: "Homemade Perceptron Classifier",
      link: "https://github.com/Cole-Krudwig/machine-learning-tutorials/blob/main/01_perceptrons/linear_blob_classification.ipynb",
      description:
        "Create a linear blob classifier by developing a from-scratch neural network.",
    },
  ];

  return (
    <section className="mt-16">
      <div className="container mx-auto">
        <div className=" mb-8">
          <h1 className="md:text-6xl text-4xl font-bold mb-4">Teaching</h1>
          <p className="text-lg">Educating as a tool for mastery.</p>
        </div>

        {teachings.map((teach, index) => (
          <TeachingCard
            key={index}
            event={teach.event}
            date={teach.date}
            title={teach.title}
            link={teach.link}
            description={teach.description}
          />
        ))}
      </div>
    </section>
  );
};

export default Teaching;
