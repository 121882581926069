import React from "react";
import { FaGithub, FaLinkedin, FaKaggle, FaEnvelope } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { motion } from "framer-motion";

const ContactHeader: React.FC = () => {
  return (
    <section className="container mt-8 mx-auto">
      <div className="flex items-center space-x-4 sm:space-x-8">
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.0 }}
        >
          <a
            href="mailto:ckrudwig@gmail.com"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-teal-900"
          >
            <FaEnvelope className="icon" size={42} />
          </a>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.0, delay: 0.5 }}
        >
          <a
            href="https://www.linkedin.com/in/cole-j-krudwig/"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-teal-900"
          >
            <FaLinkedin className="icon" size={42} />
          </a>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.0, delay: 1.0 }}
        >
          <a
            href="https://github.com/Cole-Krudwig"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-teal-900"
          >
            <FaGithub className="icon" size={42} />
          </a>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.0, delay: 1.5 }}
        >
          <a
            href="https://www.kaggle.com/colekrudwig"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-teal-900"
          >
            <FaKaggle className="icon" size={42} />
          </a>
        </motion.div>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1.0, delay: 2.0 }}
        >
          <a
            href="https://twitter.com/colekrudwig"
            target="_blank"
            rel="noopener noreferrer"
            className="hover:text-teal-900"
          >
            <FaXTwitter className="icon" size={42} />
          </a>
        </motion.div>
      </div>
    </section>
  );
};

export default ContactHeader;
