// src/components/Projects.tsx
import React from "react";
import ProjectCard from "./ProjectCard";

const Projects: React.FC = () => {
  // Dummy data for demonstration
  const projects = [
    {
      image: "/projects/finbot.png", // Replace with actual image URL
      title: "FinBot",
      link: "https://github.com/Cole-Krudwig/FinBot",
      description: "Automate equity and market research tasks.",
    },
    {
      image: "/projects/Numeri_Logo.png", // Replace with actual image URL
      title: "Numeri",
      link: "https://github.com/Cole-Krudwig/Numeri",
      description:
        "Create open-source and community-oriented solutions to tackle current challenges in access to education.",
    },
  ];

  return (
    <section className="mt-16">
      <div className="container mx-auto">
        <div className="mb-8">
          <h1 className="md:text-6xl text-4xl font-bold mb-4">Featured Work</h1>
          <p className="text-lg">
            A compilation of my latest projects and ideas.
          </p>
        </div>
        {projects.map((project, index) => (
          <ProjectCard
            key={index}
            image={project.image}
            title={project.title}
            link={project.link}
            description={project.description}
          />
        ))}
        <hr className="border-t-2 border-gray-700" />
      </div>
    </section>
  );
};

export default Projects;
